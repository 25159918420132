<template>
    <v-container fluid class="ma-4">
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="search"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4"
                item-value="name"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <v-text-field
                                v-model="search.id"
                                variant="outlined"
                                hide-details
                                type="number"
                                placeholder="Search id..."
                                class="ma-1"
                                @input="loadItems"
                                density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.first_name"
                                hide-details
                                variant="outlined"
                                placeholder="Search name..."
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.email"
                                variant="outlined"
                                hide-details
                                placeholder="Search email..."
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="search.tree_user_id"
                                variant="outlined"
                                hide-details
                                placeholder="Личники"
                                class="ma-1"
                                density="compact"
                                @input="loadItems"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <input class="date-picker-native" type="text" @blur="loadItems" onfocus="(this.type='date')" v-model="dateFrom" placeholder="Др от">
                    </v-col>
                    <v-col>
                        <input class="date-picker-native" type="text" @blur="loadItems" onfocus="(this.type='date')" v-model="dateTo" placeholder="Др до">
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="search.zodiac"
                                hide-details
                                variant="outlined"
                                :items="zodiacsMap"
                                return-object
                                placeholder="Zodiac..."
                                class="ma-1"
                                item-text="title"
                                density="compact"
                                @update:modelValue="loadItems"
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                          v-model="search.pricing_plan"
                          hide-details
                          variant="outlined"
                          :items="pricingPlans"
                          return-object
                          placeholder="Pricing plan..."
                          class="ma-1"
                          item-text="title"
                          density="compact"
                          @update:modelValue="loadItems"
                      ></v-select>
                    </v-col>
                    <v-col class="reset" @click="reset">Сброс</v-col>
                </v-row>
            </template>
            <template v-slot:[`item.total_profit`]="{ item }">
                <router-link :to="`/transactions?id=${item.selectable.id}&type=reward&type=pair_bonus&type=deposit&reward_source=pair_bonus&type=withdrawal&type=account_transfer&type=pricing_plan_purchase`">${{ getFormattedCurrency(item.selectable.total_profit, 4) }}</router-link>
            </template>
            <template v-slot:[`item.activated_at`]="{ item }">
                {{ item.selectable.activated_at ? moment(item.selectable.activated_at * 1000).format('DD.MM.YYYY HH:mm:ss', true) : 'Never' }}
            </template>
            <template v-slot:[`item.joined_at`]="{ item }">
                {{ moment(item.selectable.joined_at * 1000).format('DD.MM.YYYY HH:mm:ss', true) }}
            </template>
            <template v-slot:[`item.directs_amount`]="{ item }">
                <div class="link" @click="item.selectable.directs_amount ? goToRefferals(item) : ''">{{ item.selectable.directs_amount}}</div>
            </template>
            <template v-slot:[`item.total_balance`]="{ item }">
                <router-link  :to="`/accounts?id=${item.selectable.id}`">${{ getFormattedCurrency(item.selectable.total_balance, 4) }}</router-link>
            </template>
            <template v-slot:[`item.referrer`]="{ item }">
                <div class="link" @click="item.selectable.referrer ? goToUsers(item.selectable.referrer.id) : ''">
                  {{ item.selectable.referrer ? item.selectable.referrer.first_name +
                    ' ' + item.selectable.referrer.last_name : '-'
                  }}</div>
            </template>
            <template v-slot:[`item.fast_team_active_until`]="{ item }">
                {{ item.selectable.fast_team_active_until ? moment(item.selectable.fast_team_active_until * 1000).format('DD.MM.YYYY HH:mm:ss', true) : 'No FTB' }}
            </template>
            <template v-slot:[`item.cashback_left`]="{ item }">
                ${{ getFormattedCurrency(item.selectable.cashback_left) }}
            </template>
            <template v-slot:[`item.current_rates`]="{ item }">
                {{ item.selectable.current_rates.main + '% (до ' + item.selectable.current_rates.until_date + ')'}}
            </template>
            <template v-slot:[`item.active_until`]="{ item }">
                {{ item.selectable.active_until ? moment(item.selectable.active_until * 1000).format('DD.MM.YYYY HH:mm:ss', true) : 'Never' }}
            </template>
            <template v-slot:[`item.is_trading_enabled`]="{ item }">
                {{ item.selectable.is_trading_enabled ? 'Да' : 'Нет' }} {{ item.selectable.trading_block_reason ? '(' + item.selectable.trading_block_reason + ')' : '' }}
            </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
import {getPricingPlans, getUsers} from "../plugins/api-service";
  const zodiac = require('zodiac-signs')('ru');
  import currencyFormatter from "../mixins/currencyFormatter";
  import moment from 'moment';

  const zodiacsMap = [
    {
      title: 'Козерог',
      fromD: 21,
      fromM: 12,
      toD: 19,
      toM: 1
    },
    {
      title: 'Водолей',
      fromD: 20,
      fromM: 1,
      toD: 18,
      toM: 2
    },
    {
      title: 'Рыбы',
      fromD: 19,
      fromM: 2,
      toD: 20,
      toM: 3
    },
    {
      title: 'Овен',
      fromD: 21,
      fromM: 3,
      toD: 19,
      toM: 4
    },
    {
      title: 'Телец',
      fromD: 20,
      fromM: 4,
      toD: 20,
      toM: 5
    },
    {
      title: 'Близнецы',
      fromD: 21,
      fromM: 5,
      toD: 21,
      toM: 6
    },
    {
      title: 'Рак',
      fromD: 22,
      fromM: 6,
      toD: 22,
      toM: 7
    },
    {
      title: 'Лев',
      fromD: 23,
      fromM: 7,
      toD: 22,
      toM: 8
    },
    {
      title: 'Дева',
      fromD: 23,
      fromM: 8,
      toD: 22,
      toM: 9
    },
    {
      title: 'Весы',
      fromD: 23,
      fromM: 9,
      toD: 22,
      toM: 10
    },
    {
      title: 'Скорпион',
      fromD: 23,
      fromM: 10,
      toD: 22,
      toM: 11
    },
    {
      title: 'Стрелец',
      fromD: 23,
      fromM: 11,
      toD: 21,
      toM: 12
    }
  ];

  export default {
    name: 'UsersView',
    mixins: [currencyFormatter],
    data: () => ({
      itemsPerPage: 30,
      zodiacsMap,
      menu: false,
      dates: [],
      headers: [
        { title: 'ID', align: 'start', key: 'id'},
        { title: 'Имя', key: 'first_name', align: 'start', sortable: false },
        { title: 'Фамилия', key: 'last_name', align: 'start', sortable: false },
        { title: 'Email', key: 'email', align: 'start', sortable: false, },
        { title: 'Тариф', key: 'pricing_plan.title', align: 'start', sortable: false, },
        { title: 'Реферрер', key: 'referrer', align: 'start', sortable: false, },
        { title: 'Статус', key: 'level.id', value:'level.title', align: 'start' },
        { title: 'FTB до', key: 'fast_team_active_until', align: 'start', sortable: false, },
        { title: 'Ост. кэшбек', key: 'cashback_left', align: 'start', sortable: false, },
        { title: 'Итоговый доход', key: 'total_profit', align: 'start'},
        { title: 'Сейчас на счету', key: 'total_balance', align: 'start'},
        { title: 'Команда', key: 'team_amount', align: 'start' },
        { title: 'Личные', key: 'directs_amount', align: 'start' },
        { title: 'Ставка', key: 'current_rates', align: 'start', sortable: false, width: '160px' },
        { title: 'Current PV', key: 'current_week_pv', align: 'start', sortable: false, },
        { title: 'Дата рождения', key: 'birth_date', align: 'start' },
        { title: 'День недели рождения', key: 'birthday', align: 'start', sortable: false, },
        { title: 'Знак зодиака', key: 'zodiac', align: 'start', sortable: false, },
        { title: 'Дата регистрации', key: 'joined_at', align: 'start' },
        { title: 'Тариф активирован', key: 'activated_at', align: 'start' },
        { title: 'Тариф активен до', key: 'active_until', align: 'start', sortable: false, },
        { title: 'Торговля разрешена', key: 'is_trading_enabled', align: 'start', sortable: false, },
        { title: 'Циклов завершено', key: 'trading_cycles_ended_count', align: 'start', sortable: false },
      ],
      dateFrom: '',
      dateTo: '',
      serverItems: [],
      loading: true,
      totalItems: 0,
      search: {
        id: null,
        first_name: '',
        email: ''
      },
      sort: {},
      pricingPlans: []
    }),
    async mounted() {
      this.loadItems()
      const response = await getPricingPlans();
      if (response.success) {
        this.pricingPlans = response.result;
      }
    },
    methods: {
      moment,
      reset() {
        this.search = {...{
          id: null,
            first_name: '',
            email: ''
        }};

        this.dateFrom = '';
        this.dateTo = '';

        this.$router.push('/users');
        this.loadItems();
      },
      goToRefferals(item) {
        this.search.tree_user_id = item.selectable.id;
        this.search.tree_direction = 'down';
        this.loadItems();
      },
      goToUsers(userId) {
        this.search.id = userId;
        this.loadItems();
      },
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        if(this.$route.query.id) {
          this.search.id = this.$route.query.id;
        }

        if(this.$route.query.name) {
          this.search.first_name = this.$route.query.name;
        }

        this.loading = true;

        let page = params?.page || 1;
        let itemsPerPage = params?.itemsPerPage || (this.itemsPerPage > 0 ? this.itemsPerPage : this.totalItems);

        let requestParams = {
          offset: (page - 1) * itemsPerPage,
          limit: page === -1 ? this.totalItems : itemsPerPage,
          id: this.search.id || this.$route.params.id,
        };

        if(this.search.first_name) {
          requestParams.name_contains = this.search.first_name;
        }
        if(this.search.email) {
          requestParams.email_contains = this.search.email;
        }
        if(this.search.tree_user_id) {
          console.log('this.search.tree_user_id', this.search.tree_user_id);
          requestParams.tree_user_id = this.search.tree_user_id;
        }
        if(this.search.tree_direction) {
          requestParams.tree_direction = this.search.tree_direction;
        }

        if(this.sort.order_direction && this.sort.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }

        if(params?.order_direction && params?.order_by) {
          requestParams.order_by = params.order_by;
          requestParams.order_direction = params.order_direction;
        }

        if(this.search.zodiac) {
          let zodiac = this.search.zodiac;
          requestParams.birth_date_month_since = zodiac.fromM;
          requestParams.birth_date_month_until = zodiac.toM;
          requestParams.birth_date_day_since = zodiac.fromD;
          requestParams.birth_date_day_until = zodiac.toD;
        }


        if(this.dateFrom) {
          let date = new Date(this.dateFrom);
          requestParams.birth_date_year_since = date.getFullYear();
          requestParams.birth_date_month_since = date.getMonth() + 1;
          requestParams.birth_date_day_since = date.getDate();
        }

        if(this.dateTo) {
          let date = new Date(this.dateTo);
          requestParams.birth_date_year_until = date.getFullYear();
          requestParams.birth_date_month_until = date.getMonth() + 1;
          requestParams.birth_date_day_until = date.getDate();
        }

        if(this.search.pricing_plan) {
          requestParams.pricing_plan_id = this.search.pricing_plan.id;
        }

        await getUsers(requestParams).then(response=>{
          if(response.success) {
            let weekDays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
            for(let resultItem of response.result?.items) {
                const birthdate = new Date(resultItem.birth_date);
                let day = birthdate.getDate();
                let month = birthdate.getMonth() + 1;

                resultItem['zodiac'] = zodiac.getSignByDate({ day, month }).name;
                resultItem['birthday'] = weekDays[birthdate.getDay()];
            }

            this.serverItems = response.result?.items;
            this.totalItems = response.result?.total;
            this.loading = false;
          }
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
    .link {
        color: #283593;
        cursor: pointer;

        &:hover {
            color: rgba(#283593, .9);
        }
    }
  .adm-container {
    min-height: calc(100vh - 64px);
  }

    .reset {
        display: flex;
        align-items: center;
    }

    .date-picker-native {
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding: 0 8px;
        display: flex;
        flex-wrap: wrap;
        letter-spacing: 0.009375em;
        min-width: 0;
        position: relative;
        width: 100%;
        height: 44px;
        font-size: 16px;
        font-family: Roboto, sans-serif;
        border-radius: 4px;
        margin-top: 4px;

        &:invalid::-webkit-datetime-edit {
            color: rgba(0, 0, 0, 0.38);
        }
    }
</style>