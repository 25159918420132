<template>
    <v-container fluid class="ma-4">
        <v-data-table-server
                v-model:items-per-page="itemsPerPage"
                :search="search"
                :headers="headers"
                :items-length="totalItems"
                :items="serverItems"
                :loading="loading"
                class="elevation-1 pa-4"
                item-value="name"
                @update:itemsPerPage="changeItemsPrePage"
                @update:page="loadItemsPage"
                @update:sortBy="loadSortPage"
                @update:modelValue="loadItems"
        >
            <template v-slot:top>
                <v-row>
                    <v-col>
                        <VueDatePicker
                                v-model="date"
                                model-type="yyyy-MM-dd"
                                :disabled-week-days="[7, 0, 1, 2, 3, 4, 5]"
                                @update:model-value="loadItems"
                                auto-apply
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.week_started_at`]="{ item }">
                {{ moment(item.selectable.week_started_at * 1000).format('DD.MM.YYYY') }} -
                {{ moment(item.selectable.week_ended_at * 1000).format('DD.MM.YYYY') }}
            </template>
            <template v-slot:[`item.ft`]="{ item }">
                {{ item.selectable.fast_team_amount }}/{{ item.selectable.cashback_amount }}
            </template>
            <template v-slot:[`item.activations_count`]="{ item }">
                {{ item.selectable.activations_count }}
            </template>
            <template v-slot:[`item.pro_upgrades_count`]="{ item }">
                {{ item.selectable.pro_upgrades_count }}
            </template>
            <template v-slot:[`item.rewards_total_amount`]="{ item }">
                {{ item.selectable.rewards_total_amount }} ({{ item.selectable.rewards_to_turnover_ratio }} %)
            </template>
        </v-data-table-server>
    </v-container>
</template>
<script>
  import { getWeeksResults } from "../plugins/api-service";
  import moment from 'moment';
  import currencyFormatter from "../mixins/currencyFormatter"

  export default {
    name: 'UsersAccounts',
    mixins: [currencyFormatter],
    data: () => ({
      itemsPerPage: 10,
      headers: [
        { title: 'Финансовая неделя', key: 'week_started_at', align: 'start', sortable: false },
        { title: 'Активации', key: 'activations_count', align: 'start', sortable: false },
        { title: 'Перешли на PRO', key: 'pro_upgrades_count', align: 'start', sortable: false },
        { title: 'PV', key: 'pvs', align: 'start'},
        { title: 'Оборот', key: 'turnover', align: 'start'},
        { title: 'Бонусов выплачено', key: 'rewards_total_amount', align: 'start'},
        { title: 'FT и Cashback', key: 'ft', align: 'start'},
        { title: 'Points начислено', key: 'points_earned', align: 'start'},
        { title: 'WW выплачено', key: 'world_wide_bonus_total_amount', align: 'start'},
        { title: 'Офисам начислено', key: 'offices_received', align: 'start', sortable: false },
        { title: 'Остаток', key: 'company_profit_total_amount', align: 'start'},
        { title: 'Циклов завершено', key: 'trading_cycles_completed', align: 'start'},
        { title: 'Снято комиссии', key: 'fees_total_amount', align: 'start'},
        { title: 'Депозиты onchain', key: 'deposits_on_chain_total_amount', align: 'start'},
      ],
      serverItems: [],
      loading: true,
      totalItems: 9999999,
      page: 1,
      search: {
        id: null,
        first_name: '',
        email: ''
      },
      sort: {},
      date: '',
    }),
    mounted() {
      this.loadItems()
    },
    methods: {
      moment,
      async loadItemsPage(page) {
        this.loadItems({
          page,
          order_by: this.sort.order_by,
          order_direction: this.sort.order_direction
        });
      },
      async loadSortPage(params) {
        let requestParams = {};
        if(params && params.length && params[0]['key'] && params[0]['key']) {
          requestParams.order_by = params[0].key;
          requestParams.order_direction = params[0].order;
        }

        this.sort = requestParams;

        this.loadItems(requestParams);
      },
      async loadItems(params) {
        this.loading = true;

        if(this.$route.query.id) {
          this.search.id = this.$route.query.id;
        }

        this.$route.query = {};

        let page = params?.page || 1;
        let itemsPerPage = params?.itemsPerPage || (this.itemsPerPage > 0 ? this.itemsPerPage :  this.totalItems);

        let requestParams = {
          offset: (page - 1) * itemsPerPage,
          limit: page === -1 ? this.totalItems : itemsPerPage
          // order_direction: 'desc'
        };

        if(this.date) {
          requestParams.from_week_started_at = this.date;
        }

        await getWeeksResults(requestParams).then(response=>{
          if(response.success) {
            this.serverItems = response.result;
            this.loading = false;
          }
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
    .adm-container {
        min-height: calc(100vh - 64px);
    }
</style>